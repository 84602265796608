
import { FormPage } from '@/mixins'
import { EventModel, EventPostStatus, UpdateEventModel } from 'tradingmate-modules/src/models/api/events'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import ImageGallery from '@/components/inputs/ImageGallery.vue'
import Copyable from '@/components/inputs/Copyable.vue'
import Modal from '@/components/actions/Modal.vue'
import { InputDateTime, InputText, InputTextarea } from '@/components/inputs'
import AttachedObjectType from 'tradingmate-modules/src/models/api/files/AttachedObjectType'
import { FileAttachmentType } from 'tradingmate-modules/src/models/api/files'
import ContentHtmlInput from '@/components/inputs/ContentHtmlInput.vue'

@Component({
  components: {
    ContentHtmlInput,
    ImageGallery,
    Copyable,
    Modal,
    InputText,
    InputDateTime,
    InputTextarea
  }
})
export default class EventEdit extends Mixins(FormPage) {
  private eventModel: EventModel | null = null
  private updateEventModel: UpdateEventModel | null = null
  private savedSuccessfully = false;

  private deleteModalActive = false;
  private pulishModalActive = false;
  private unpulishModalActive = false;

  private gettingEvent = false
  private objectType = AttachedObjectType.Event
  private fileType = FileAttachmentType.ImageGalleryItem

  mounted (): void {
    this.getPost()
  }

  getPost (): void {
    const eventId = this.$route.params.id
    if (!eventId) {
      this.$router.push('/events')
      return
    }

    this.gettingEvent = true

    Services.API.Events.GetEvent(eventId)
      .then((event) => {
        this.eventModel = event
        this.modelToUpdateModel()
      }).catch(() => {
        this.$router.push('/events')
      }).finally(() => {
        this.gettingEvent = false
      })
  }

  modelToUpdateModel (): void {
    if (this.eventModel === null) return
    this.updateEventModel = {
      Status: this.eventModel.Status,
      Name: this.eventModel.Name,
      Body: this.eventModel.Body,
      EventStartDateTime: this.eventModel.EventStartDate,
      EventEndDateTime: this.eventModel.EventEndDate,
      Location: this.eventModel.Location,
      ExternalLink: this.eventModel.ExternalLink
    }
  }

  savePost (): void {
    if (this.eventModel === null) return
    if (this.updateEventModel === null) return

    this.httpError = null
    this.savedSuccessfully = false
    this.loading = true

    Services.API.Events.UpdateEvent(this.eventModel.EventId, this.updateEventModel)
      .then((returnModel) => {
        this.eventModel = returnModel
        this.modelToUpdateModel()
        this.savedSuccessfully = true
        this.loading = true
      }).catch((error) => {
        this.httpError = error
      }).finally(() => {
        this.loading = false
        this.scrollToTop()
      })
  }

  publishPost (): void {
    this.pulishModalActive = false
    if (this.updateEventModel === null) return
    this.updateEventModel.Status = EventPostStatus.Published
    this.savePost()
  }

  unpublishPost (): void {
    this.unpulishModalActive = false
    if (this.updateEventModel === null) return
    this.updateEventModel.Status = EventPostStatus.Draft
    this.savePost()
  }

  deletePost (): void {
    if (this.eventModel === null) return
    Services.API.Events.DeleteEvent(this.eventModel.EventId)
      .catch((error) => { this.httpError = error })
    this.$router.push({ path: '/events' })
  }

  scrollToTop (): void {
    const scrollPoint = this.$refs.scrollPoint as HTMLElement
    scrollPoint.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }
}
